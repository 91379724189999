import { useQuery } from '@tanstack/react-query';
import { supabase } from '../utils';

const fetchIsAdmin = async () => {
  const session = await supabase.auth.getSession();
  if (!session || !session.data.session) {
    throw new Error('Not logged in fetchIsAdmin');
  }
  const emailDomain = session.data.session.user.email!.split('@')[1];
  return emailDomain === 'heyrafi.com';
};

export const useIsAdmin = () =>
  useQuery({
    queryKey: ['isAdmin'],
    queryFn: () => fetchIsAdmin(),
  });
