import { Flex, Loader } from '@mantine/core';
import { ARV_Sidenav } from './OfferQueue/OfferQueue';
import { PropertyInfo } from './PropertyInfo/PropertyInfo';
import { OfferSettings } from './OfferSettings/OfferSettings';
import { Campaign } from 'shared/db';
import { CampaignOnboarding } from 'fe/feature/campaign';
import { useOrganisationStore, useRecommendationsStore } from 'fe/queries';

interface ARV_PageProps {
  campaign: Campaign;
}

export function ARV_Page({ campaign }: ARV_PageProps) {
  if (!campaign) {
    return null;
  }
  const { organisation } = useOrganisationStore();
  if (!organisation) return null;

  const {
    recommendations,
    activeOffer,
    isLoading,
    skipOffer,
    rejectOffer,
    isRejecting,
    approveOffer,
  } = useRecommendationsStore(campaign.id);

  if (isLoading) {
    return (
      <Flex h="100%" w="100%" align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  if (!recommendations || !recommendations.length || !activeOffer) {
    return <CampaignOnboarding campaign={campaign} />;
  }

  return (
    <Flex h="100%" w="100%">
      <ARV_Sidenav campaign={campaign} />

      <PropertyInfo property={activeOffer.property} isLoading={isLoading} />

      <OfferSettings
        organisation={organisation}
        property={activeOffer.property}
        rejectOffer={(reason) => rejectOffer(reason)}
        isRejecting={isRejecting}
        skipOffer={() => skipOffer()}
        onApprove={approveOffer}
      />
    </Flex>
  );
}
